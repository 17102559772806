import ScrollNavigation from "../componentes/ScrollNavigation";

const ButtomButton = () => {
  return (
    <div>
      <ScrollNavigation />
    </div>
  );
};

export default ButtomButton;
